export const appSettings = {
  dateFormat: {
    standard: 'yyyy-MM-dd',
    display: 'dd/MM/yyyy',
    printDisplay: 'dd/MMM/yyyy',
    chart: 'yyyyMM',
    chartTootip: 'MMM yyyy',
    fullMonth: 'MMMM yyyy',
    short: 'dd/MM/yy',
    displayTime: 'dd/MM/yyyy, hh:mm a',
    weekdayDisplay: 'cccc, LLL dd',
    displayMonthAndYear: 'LLL yyyy',
    nowMonth: 'yyyy-MM-01',
    datePicker: 'yyyy-MM-dd'
  },
  types: [
    { key: 'Domain', value: 'domains'},
    { key: 'Name Server', value: 'nameservers' },
    { key: 'Registrar', value: 'registrar' }
  ]
};

// types: [
//   { key: 'Domain', value: 'domains'},
//   { key: 'Name Server', value: 'nameservers' },
//   { key: 'Entity', value: 'entity' },
//   { key: 'Registrar', value: 'registrar' }
// ]
// frequencies: [
//   { key: 'Domain', value: 'domain'},
//   { key: 'TLD', value: 'tld' },
//   { key: 'IP', value: 'ip' },
//   { key: 'AS Number', value: 'autnum' },
//   { key: 'Entity', value: 'entity' },
//   { key: 'Registrar', value: 'registrar' },
//   { key: 'URL', value: 'url' },
//   { key: 'JSON', value: 'json' }
// ]