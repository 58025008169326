import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { getAsyncFromAxios } from '../atoms/searchEventHandler';
import { searchFilterAtom } from '../atoms/searchAtoms';
import { Height } from '@mui/icons-material';

const Resource = () => {
  const [resource, setResource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location?.search);
  const [searchFilter,] = useAtom(searchFilterAtom);
  const keyword = searchParams.get('keyword');
  const resourceType = searchParams.get('type');
  const navigate = useNavigate();

  async function getResource() {
    setIsLoading(true);

    const resultRaw = await getAsyncFromAxios(keyword, resourceType);
    const result = resultRaw?.message?.split(/\r?\n/) ?? [];

    setResource(result);
    setIsLoading(false);
  }

  function goBack() {
    if (searchFilter.type === 'entity' || searchFilter.type === 'registrar' || searchParams.get('bth') == 'true' ) { // bth = back to home page 
      navigate('/');
    } else if (searchFilter.keyword) {
      navigate(-1);
    } else {
      navigate('/search');
    }
  }

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Container id='rdap-resource-container' maxWidth="lg" sx={{ marginTop: 10, p: 2 }}>
      <Grid container justifyContent="center">
        <Grid xs={12} md={8}>
          <Typography sx={{ marginLeft: '50%', marginRight: '50%'}}>{isLoading && <CircularProgress sx={{ mt: 3 }} />}</Typography>
          {isLoading === false && 
            <Stack direction='column' spacing={2}>
              <Button id='rdap-back-to-search-results-button' variant="contained" onClick={goBack} sx={{ width: '70px'}}>
                <ArrowBackIcon />
              </Button>
              <br />
              {resource?.map((item, index) => {
                return (
                  item == '' ? 
                  <br />
                  :
                  <Typography key={`resource-${index}`} style={{ textAlign: 'left', margin: '1px'}}>{item}</Typography>
                )
              })}
            </Stack>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default Resource;
